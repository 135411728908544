import React from "react";
import styles from "./introduction.module.scss";
import introduction from "../../../../images/blogDetailIntroduction.png";

class Introduction extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["introduction"]}>
          <img className={styles["introduction-img"]} src={introduction}></img>
          <div className={styles["introduction-text"]}>Sould Reader Blog</div>
        </div>
      </div>
    );
  }
}

export default Introduction;
