import { useNavigate } from "react-router-dom";
import styles from "./detail.module.scss";

function Detail({ data }) {
  const navigate = useNavigate();

  const handleAppDetail = () => {
    navigate(`${data.id}`);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["name"]}>
        <div className={styles["title"]}>{data.name}</div>
        <div className={styles["content"]}>
          Gói chọn: {data.package}
        </div>
      </div>
      <div className={styles["time"]}>
        <div className={styles["title"]}>Thời gian</div>
        <div className={styles["content"]}>{data.time}</div>
      </div>
      <div className={styles["btn-view"]} onClick={handleAppDetail}>
        <div className={styles["text"]}>Sắp tới</div>
      </div>
    </div>
  );
}

export default Detail;
