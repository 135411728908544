import { useNavigate } from "react-router-dom";
import styles from "./OrderHistory.module.scss";

function OrderHistory() {

    const navigate = useNavigate();
    const handleOrder = () => {
        navigate(`Meeting/1`,{
          state: {
          }
        });
    }

  return (
    <div className={styles["order-detail-container"]}>
      <div className={styles["information"]}>
        <div className={styles["bundle"]}>
          <div className={styles["bundle-tile"]}>Chọn gói</div>
          <div className={styles["content"]}> - </div>
        </div>
        <div className={styles["bundle"]}>
          <div className={styles["bundle-tile"]}>Thời gian</div>
          <div className={styles["content"]}>11AM - 26/10/24</div>
        </div>
        <div className={styles["bundle"]}>
          <div className={styles["bundle-tile"]}>Giá</div>
          <div className={styles["content"]}>VND</div>
        </div>
      </div>
      <div className={styles["button-paynow"]}>
        <div className={styles["paynow-text"]} onClick={handleOrder}>Vào xem ngay</div>
      </div>
    </div>
  );
}
export default OrderHistory;
