import React from "react";
import { apiService, ApiService } from "../../env/apiService";

class SigninHandler extends React.Component {
  HandleSignin = async (UserName, Password, Email) => {
    if (!UserName || !Password || !Email) {
      throw new Error("Vui lòng điền đầy đủ thông tin. ");
    }
    try {
      const body = {
        UserName,
        Password,
        Email,
      };
      const data = await apiService.post("Auth/Register", body);
      
    } catch (error) {
      throw new Error(error.message || "Đăng ký thất bại");
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}
export default SigninHandler;
