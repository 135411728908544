import React from "react";

import styles from "./ReaderDetail.module.scss";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import Comment from "./FeedBack/Comment";
import { Link } from "react-router-dom";

class ReaderDetail extends React.Component {
  componentDidMount() {}
  render() {
    const pathSegments = window.location.pathname.split("/");
    const id = pathSegments[pathSegments.length - 1];
    return (
      <>
        <div className="home">
          <div className="div">
            <Headers></Headers>
            <div> ngu {id}</div>
            <div className={styles["detail-container"]}>
              <div className={styles["profile-container"]}>
                <div className={styles["avatar-profile"]}>
                  <div className={styles["avatar"]}>
                    <img
                      className={styles["avatar-image"]}
                      src="https://c.animaapp.com/0heN5NVk/img/image-6@2x.png"
                      alt="avatar"
                    ></img>
                  </div>
                  <div className={styles["reader-name"]}>Tuyen Tarot</div>
                  {/* <div className={styles["feedback"]}>
                  <div className={styles["star-containter"]}>
                    {[...Array(5)].map((_, index) => (
                      <img
                        key={index}
                        className={styles["star-image"]}
                        src="https://c.animaapp.com/O8yYDpAn/img/image-11-2@2x.png"
                      ></img>
                    ))}
                  </div>
                  <div className={styles["avarage-star"]}>4.6</div>
                  <div className={styles["num-customer"]}>130 Khách hàng</div>
                </div> */}
                </div>
                <div className={styles["detail-profile"]}>
                  <div className={styles["content-container"]}>
                    <div className={styles["title"]}>Kinh nghiệm: </div>
                    <div className={styles["content"]}>+5 năm</div>
                  </div>

                  <div className={styles["content-container"]}>
                    <div className={styles["title"]}>Liên lạc: </div>
                    <div className={styles["content"]}></div>
                  </div>

                  <div className={styles["content-container"]}>
                    <div className={styles["title"]}>Chuyển môn: </div>
                    <div className={styles["content"]}>
                      {" "}
                      Công việc, tình cảm, mối quan hệ, phát triển bản thân.
                    </div>
                  </div>

                  <div
                    className={`${styles["content-container"]} ${styles["wrap"]}`}
                  >
                    <div className={styles["title"]}>Mô tả: </div>
                    <div className={styles["content"]}>
                      Với niềm đam mê sâu sắc dành cho Tarot và nghệ thuật đọc
                      bài, tôi mang đến cho bạn những trải nghiệm khám phá bản
                      thân và dự đoán tương lai thông qua những lá bài bí ẩn.
                      Giúp khách hàng hiểu rõ hơn về tình trạng hiện tại, những
                      trở ngại, cũng như cơ hội tiềm ẩn.
                    </div>
                  </div>

                  <div
                    className={`${styles["content-container"]} ${styles["wrap"]}`}
                  >
                    <div className={styles["title"]}>
                      Thành tựu và phản hồi khách hàng:
                    </div>
                    <div className={styles["content"]}>
                      Tuyền đã tư vấn cho hơn 100 khách hàng, giúp họ tìm ra
                      hướng đi đúng đắn trong công việc, tình cảm và các mối
                      quan hệ. Nhiều khách hàng đã phản hồi rằng Tuyền mang đến
                      sự an tâm hơn trong cuộc sống. Những buổi đọc của Tuyền
                      thường nhận được đánh giá cao bởi sự sâu sắc, và đặc biệt
                      là khả năng giải quyết các vấn đề phức tạp mà không phán
                      xét.
                    </div>
                  </div>

                  <div
                    className={`${styles["content-container"]} ${styles["wrap"]}`}
                  >
                    <div className={styles["title"]}>
                      Lời nhắn từ Tuyền Tarot:
                    </div>
                    <div className={styles["content"]}>
                      "Bói bài tarot không phải để đoán trước tương lai, mà để
                      giúp bạn hiểu rõ bản thân và tìm ra những gì đang chờ đợi
                      phía trước. Đó là một hành trình của khám phá và kết nối,
                      và tôi rất hân hạnh được đồng hành cùng bạn trên hành
                      trình này."
                    </div>
                  </div>

                  <div className={styles["content-container"]}>
                    <div className={styles["title"]}>Các Gói: </div>
                    <div className={styles["content"]}></div>
                  </div>
                </div>
              </div>
              <div className={styles["action-container"]}>
                <Link to={`/Readers/Orders/${id}`} className={styles["button-action"]}>
                  <div className={styles["button-text"]}>Bói ngay</div>
                </Link>

                <div className={styles["button-action"]}>
                  <div className={styles["button-text"]}>Lịch làm việc</div>
                </div>

                <div className={styles["button-action"]}>
                  <div className={styles["button-text"]}>Chat</div>
                </div>
              </div>
              <div className={styles["comment-container"]}>
                <Comment></Comment>
              </div>
            </div>
            <Footers></Footers>
          </div>
        </div>
      </>
    );
  }
}
export default ReaderDetail;
