import React from "react";
import image from "../../../../../images/Blog main image.png";
import styles from "./NewestBlogItem.module.scss";

class NewestBlogItem extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["item-container"]}>
            <img className={styles["image-blog"]} src={image}>
            </img>
            <div className={styles["overview-item"]}>
                <div className={styles["item-title"]}>Tổng quan về bộ bài Tarot</div>
                <div className={styles["item-information"]}>20.5K views | 12 comments</div>
            </div>
        </div>
      </div>
    );
  }
}
export default NewestBlogItem;
