import Profile from "../../Reader/Order/ReaderProfile/Profile/Profile.module";
import styles from "./content.module.scss";
import OrderHistory from "./OrderHistory/OrderHistory.module";
function Content() {
  return (
    <div className={styles["container"]}>
      <div className={styles["introduction"]}>
        Thời điểm đã đến! Chào mừng bạn đến với buổi gặp mặt cùng tarot reader
        của chúng tôi. Hãy sẵn sàng để khám phá những thông điệp và trải nghiệm
        thú vị mà các lá bài mang lại. Rất vui được đồng hành cùng bạn trong
        hành trình này!
      </div>
      <div className={styles["tarot-reader"]}>
        <Profile></Profile>
      </div>
      <div className={styles["watch-now"]}>
        <OrderHistory></OrderHistory>
      </div>
    </div>
  );
}
export default Content;
