import React from "react";
import { Route, Routes } from "react-router-dom";
import Appointment from "../../views/appointment/Appointment.module";
import Meeting from "../../views/meeting/Meeting.module";
import AppointList from "../../views/appointment/AppointmentList/Appointment.module";

class AppointmentRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<AppointList></AppointList>} />
        <Route path="/:id" element={<Appointment></Appointment>} />
        <Route path="/:id/Meeting/:id" element={<Meeting></Meeting>}></Route>
      </Routes>
    );
  }
}
export default AppointmentRoutes;
