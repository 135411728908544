import React from "react";
import styles from "./Profile.module.scss";
import avatar from "../../../../../images/default_avatar.png";

class Profile extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["profile-container"]}>
          <img className={styles["avatar-profile"]} src={avatar}></img>
          <div className={styles["profile-content"]}>
            <div className={styles["name"]}>Tuyen Tarot</div>
            <div className={styles["expert"]}>
              Chuyên gia Tarot Rider-Waite{" "}
            </div>
            <div className={styles["experience"]}>5+ năm kinh nghiệm</div>
            <div className={styles["profile"]}>
              Kết hợp trực giác và kiến thức sâu về tarot, Linh tạo không gian
              thoải mái, giúp khách hàng dễ dàng chia sẻ và thấu hiểu vấn đề.
              Kết hợp trực giác và kiến thức sâu về tarot, Linh tạo không gian
              thoải mái, giúp khách hàng dễ dàng chia sẻ và thấu hiểu vấn đề.
              Kết hợp trực giác và kiến thức sâu về tarot, Linh tạo không gian
              thoải mái, giúp khách hàng dễ dàng chia sẻ và thấu hiểu vấn đề.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Profile;
