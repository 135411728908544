import React from "react";
import styles from "./BlogTab.module.scss";
import image from "../../../../../../images/blogPreview.png";
import { Link } from "react-router-dom";

class BlogTab extends React.Component {
  render() {
    const blogId = this.props.id;
    return (
      <>
        <div className={styles["blog-container"]}>
          <div className={styles["content-container"]}>
            <div className={styles["blog-image"]}>
              <img src={image} className={styles["image"]} alt="blog"></img>
            </div>
            <div className={styles["text"]}>
              <div className={styles["blog-name"]}>
                Tổng quan về Bộ bài Tarot
              </div>
              <div className={styles["blog-content"]}>
                Bộ bài Tarot kết nối tiềm thức, giúp bạn khám phá bản thân và
                nhận thông điệp từ cuộc sống.
              </div>
            </div>
            <Link to={`/Blogs/${blogId}`}>
              <div className={styles["btn-detail"]}>
                <div className={styles["detail-text"]}>XEM BÀI VIẾT</div>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default BlogTab;
