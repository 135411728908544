import React from "react";
import { Card } from "../../../utils/cardDTO";
import { apiService } from "../../../env/apiService";
import { motion } from "framer-motion";
import styles from "./CardShuffle.module.scss";

class CardResult extends React.Component {
  state = {
    isWatchingSumarize: false,
    isLoading: false,
    sumarise: "",
    selectedCard: [],
    flippedIndex: -1,
  };

  WatchSumarizeHandler = async () => {
    this.setState({
      isWatchingSumarize: !this.state.isWatchingSumarize,
      isLoading: true,
    });

    try {
      const query = this.props.selectedCardID
        .map((id) => `cardIds=${id}`)
        .join("&");
      const topicID = this.props.topicSelected;
      const endpoint = `/TopicCardMeanings/Sumary?${query}&topicId=${topicID.id}`;
      const apiResponse = await apiService.get(endpoint, {});

      this.setState({ sumarise: apiResponse, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  GetCardInformation = async () => {
    const cardIdsQuery = this.props.selectedCardID
      .map((id) => `cardIds=${id}`)
      .join("&");
    console.log("card query" + cardIdsQuery);
    const topicID = this.props.topicSelected;
    const endpoint = `/topiccardmeanings?${cardIdsQuery}&topicId=${topicID.id}`;

    try {
      const apiResponse = await apiService.get(endpoint, {});
      const card = apiResponse.map((card) => Card(card));
      this.setState({ selectedCard: card });
    } catch (error) {}
  };

  componentDidMount() {
    this.GetCardInformation();
  }

  handleCardFlip = (index) => {
    this.setState((prevState) => ({
      flippedIndex: index, // Cập nhật thẻ đã lật xong
    }));
  };

  render() {
    const {
      selectedCard,
      isWatchingSumarize,
      isLoading,
      flippedIndex,
      sumarise,
    } = this.state;
    const isWatchingResult = this.props.isWatchingResult;

    return (
      <>
        <div className={styles["select-result"]}>
          <div className={styles["card-result"]}>
            {selectedCard.map((card, index) => (
              <motion.div
                className={`${styles["result-box"]} ${
                  isWatchingResult ? styles["flipped"] : ""
                }`}
                key={index}
                initial={{ rotateY: 0 }}
                animate={{ rotateY: isWatchingResult ? 180 : 0 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  delay: index * 0.5,
                }}
                onAnimationComplete={() => this.handleCardFlip(index)}
              >
                <div className={styles["result-back"]}>
                  <img
                    className={styles["element-image"]}
                    alt="Element"
                    src={card.imageUrl}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Hiển thị meaning khi thẻ đã lật */}
        <div className={styles["meaning-result"]}>
          {selectedCard.map(
            (card, index) =>
              index <= flippedIndex && ( // Chỉ hiển thị khi đã lật xong
                <motion.div
                  className={styles["card-result"]}
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: 0.2,
                  }}
                >
                  <img
                    className={styles["element-image-detail"]}
                    alt="Element"
                    src={card.imageUrl}
                  />
                  <div className={styles["meaning-text"]}>
                    <div>
                      <div>{card.cardName}</div>
                      <p>{card.topicMeaning}</p>
                    </div>
                  </div>
                </motion.div>
              )
          )}
        </div>

        {/* Nút xem tổng hợp */}
        <div className={styles["meaning-btn"]}>
          <div
            className={styles["meaning-background"]}
            onClick={() => this.WatchSumarizeHandler()}
          >
            <div className={styles["meaning-word"]}>Xem tổng hợp</div>
          </div>
        </div>

        {/* Hiển thị tổng hợp */}
        {isWatchingSumarize && (
          <div className={styles["sumarize"]}>
            <div className={styles["card-sumarize"]}>
              {selectedCard.slice(0, 3).map((card, index) => (
                <motion.img
                  key={index}
                  className={
                    styles[`card-${["left", "middle", "right"][index]}`]
                  }
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: index * 0.5,
                  }}
                  alt="Card"
                  src={card.imageUrl}
                />
              ))}
            </div>

           {/* Hiển thị animation loading khi đang tải */}
           {isLoading ? (
              <motion.div
                className={styles["loading-spinner"]}
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
              />
            ) : (
              <motion.div
                className={styles["text-sumarize"]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                }}
              >
                <div className={styles["sumarize-content"]}>{sumarise}</div>
              </motion.div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default CardResult;
