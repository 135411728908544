import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Auth/style/Auth.scss";

const Signin = ({ signinHandler }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSign = async () => {
    try {
      await signinHandler.HandleSignin(username, password, email);
      setErrorMessage("/Auth/login");
      navigate();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login">
      <div className="div">
        <div className="card">
          <div className="overlap-group">
            <div className="ellipse" />
            <img
              className="image"
              alt="Image"
              src="https://c.animaapp.com/v0sAEoVB/img/image@2x.png"
            />
            <img
              className="img"
              alt="Image"
              src="https://c.animaapp.com/v0sAEoVB/img/image-1@2x.png"
            />
            <img
              className="image-2"
              alt="Image"
              src="https://c.animaapp.com/v0sAEoVB/img/image-2@2x.png"
            />
          </div>
        </div>

        <div className="login-input">
          <div className="text-wrapper-9">ĐĂNG KÝ</div>
          <div className="container">
            <div className="input-container">
              <div className="input">
                <div className="user-name">
                  <div className="overlap-group-4">
                    <input
                      id="username"
                      type="text"
                      placeholder="Tên đăng nhập"
                      className="input-field"
                      value={username}
                      onChange={handleChange(setUsername)}
                    />
                    <img
                      className="frame"
                      alt="Frame"
                      src="https://c.animaapp.com/v0sAEoVB/img/frame-1@2x.png"
                    />
                  </div>
                </div>
                <div className="user-name">
                  <div className="overlap-group-4">
                    <input
                      id="email"
                      type="text"
                      placeholder="E-mail"
                      className="input-field"
                      value={email}
                      onChange={handleChange(setEmail)}
                    />
                    <img
                      className="frame"
                      alt="Frame"
                      src="https://c.animaapp.com/v0sAEoVB/img/frame-1@2x.png"
                    />
                  </div>
                </div>
                <div className="password">
                  <div className="overlap-group-4">
                    <input
                      id="password"
                      type="password"
                      placeholder="Mật khẩu"
                      className="input-field"
                      value={password}
                      onChange={handleChange(setPassword)}
                    />
                    <img
                      className="frame"
                      alt="Frame"
                      src="https://c.animaapp.com/v0sAEoVB/img/frame@2x.png"
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <div className="group-6">
                <button className="overlap-group-3" onClick={handleSign}>
                  <div className="text-wrapper-4">Tạo Tài Khoản</div>
                </button>
              </div>
              <p className="b-n-kh-ng-c-t-i-kho">
                <span className="text-wrapper-3">Đã có tài khoản? </span>
                <Link className="span" to="/Auth/login">
                  <span>Đăng nhập tại đây!</span>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <Link to="/">
          <img
            className="logo"
            alt="Logo"
            src="https://c.animaapp.com/v0sAEoVB/img/logo@2x.png"
          />
        </Link>
      </div>
    </div>
  );
};

export default Signin;
