import React from "react";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import { motion } from "framer-motion";
import cardImage from "../../../images/Card.png";
import CardResult from "./CardResult";
import styles from "./CardShuffle.module.scss";
import { apiService } from "../../../env/apiService";
import { Topic } from "../../../utils/cardDTO";

class CardShuffle extends React.Component {
  state = {
    selectedCard: [],
    randomCardKey: [],
    allCard: Array(78).fill(null),
    isWatchingResult: false,
    isShuffuling: false,
    alertMessage: "",
    openTopic: false,
    openQuestion: false,
    topicSelected: null,
    questionSelected: "",
    topics: [],

    error: "",
  };
  HandleFliped = async () => {
    try {
      this.setState({
        isShuffuling: true,
      });
    } catch (error) {}
    this.setState({
      isWatchingResult: true,
    });
  };
  HandleCardClick = (index) => {
    const { selectedCard, randomCardKey } = this.state;

    if (selectedCard.includes(randomCardKey[index])) {
      this.setState({
        selectedCard: selectedCard.filter(
          (card) => card !== randomCardKey[index]
        ),
      });
    } else {
      if (selectedCard.length < 3) {
        this.setState({
          selectedCard: [...selectedCard, randomCardKey[index]],
        });
      }
    }
  };

  componentDidMount() {
    this.GetTopic();
  }

  GetTopic = async () => {
    try {
      const apiResponse = await apiService.get("/topics", {});

      const allTopics = apiResponse.map((topic) => Topic(topic));
      this.setState({
        topics: allTopics,
      });
    } catch (error) {
      console.error("Login failed:", error.message);
      this.setState({
        error: error.message,
      });
    }
  };

  ShuffleCard = () => {
    const topicSelected = this.state.topicSelected;

    if (!topicSelected) {
      this.setState({ alertMessage: "Vui lòng chọn một chủ đề!" });

      setTimeout(() => {
        this.setState({ alertMessage: "" });
      }, 1000);

      return;
    }
    this.setState({
      isShuffuling: true,
    });

    this.GetRandomCard();
  };

  GetRandomCard = async () => {
    try {
      const apiResponse = await apiService.get("/cards/randomIds", {});
      console.log("random Card" + apiResponse);
      this.setState({
        randomCardKey: apiResponse,
      });
    } catch (error) {}
  };

  setTopic = () => {
    this.setState({
      openTopic: !this.state.openTopic,
    });
  };
  setTopicSelected = (index) => {
    this.setState({
      topicSelected: index,
    });
    this.setTopic();
  };
  setQuestionSelected = (index) => {
    this.setState({
      questionSelected: index,
    });
    this.setQuestion();
  };
  setQuestion = () => {
    this.setState({
      openQuestion: !this.state.openQuestion,
    });
  };

  render() {
    const {
      openTopic,
      openQuestion,
      selectedCard,
      isWatchingResult,
      isShuffuling,
      error,
      topicSelected,
      topics,
      randomCardKey,
    } = this.state;
    const totalCards = 78; // Tổng số lá bài
    return (
      <div className="home">
        <Headers></Headers>
        <div className="content">
          <div className={styles["div-card"]}>
            <div className={styles["body"]}>
              <p className={styles["title"]}>
                {" "}
                NHẬN ĐƯỢC CÂU TRẢ LỜI BẠN CẦN VỚI
              </p>
              <div className={styles["card-img"]}>
                <img
                  className={styles["wing-2"]}
                  alt="Group"
                  src="https://c.animaapp.com/bES86A8g/img/group-3@2x.png"
                />
                <p className={styles["three-la"]}>3 Lá</p>
                <img
                  className={styles["wing-1"]}
                  alt="Group"
                  src="https://c.animaapp.com/bES86A8g/img/group-2@2x.png"
                />
              </div>

              <p className={styles["card-text"]}>BÀI TAROT</p>
              <div className={styles["option-form"]}>
                <div className={styles["title-option"]}>
                  <div
                    className={styles["option-bar"]}
                    onClick={() => this.setTopic()}
                  >
                    <div className={styles["inner-bar"]}>
                      <span className={styles["option-text"]}>
                        {this.state.topicSelected !== null
                          ? this.state.topicSelected.name
                          : "Chọn chủ đề"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${styles["dropdown-list"]} ${
                      openTopic ? styles["open"] : ""
                    }`}
                  >
                    {topics.map((topic, index) => (
                      <div className={styles["option-bar-value"]} key={index}>
                        <div
                          className={styles["inner-bar"]}
                          onClick={() => this.setTopicSelected(topic)}
                        >
                          <span className={styles["option-text"]}>
                            {topic.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={styles["shuffle-btn"]}
                  onClick={() => this.ShuffleCard()}
                >
                  <div className={styles["shuffle-btn-background"]}>
                    <div className={styles["shuffle-inner"]}>
                      <div className={styles["shuffle-text"]}>XÀO BÀI</div>
                    </div>
                  </div>
                </div>
              </div>
              {isShuffuling && (
                <motion.div
                  className={styles["result-container"]}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <div className={styles["shuffle-all-card"]}>
                    <div className={styles["card-container"]}>
                      {[...Array(totalCards)].map((_, index) => {
                        const hasKey = index < randomCardKey.length;
                        const cardKey = hasKey
                          ? randomCardKey[index]
                          : `placeholder-${index}`;

                        return (
                          <motion.div
                            className={`${styles["card-button"]} ${
                              hasKey ? styles["selectable"] : styles["disabled"]
                            } ${
                              selectedCard.includes(randomCardKey[index])
                                ? styles["active"]
                                : ""
                            }`}
                            key={cardKey}
                            onClick={() =>
                              hasKey && this.HandleCardClick(index)
                            }
                            initial={{
                              x: (index % 12) * 30,
                              y: -200,
                              rotate: Math.random() * 360,
                              opacity: 0,
                            }}
                            animate={{
                              x: 0,
                              y: 0,
                              rotate: 0,
                              opacity: 1,
                            }}
                            transition={{
                              duration: 0.5,
                              ease: "easeInOut",
                            }}
                          >
                            <img
                              className={`${styles["card-select"]} ${
                                hasKey ? styles["enabled"] : styles["disabled"]
                              }`}
                              alt={`Card ${index + 1}`}
                              src={cardImage}
                            />
                          </motion.div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles["meaning-btn"]}>
                    <div
                      className={styles["meaning-background"]}
                      onClick={() => this.HandleFliped()}
                    >
                      <div className={styles["meaning-word"]}>Xem ý nghĩa</div>
                    </div>
                  </div>
                  {isWatchingResult && (
                    <div className={styles["detail-container"]}>
                      <CardResult
                        image={cardImage}
                        selectedCardID={selectedCard}
                        isWatchingResult={isWatchingResult}
                        topicSelected={topicSelected}
                      />
                    </div>
                  )}
                </motion.div>
              )}
            </div>
          </div>
        </div>

        <Footers></Footers>
      </div>
    );
  }
}
export default CardShuffle;
