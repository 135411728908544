import React from "react";
import styles from "./Introduction.module.scss"
import introduction from "../../../../images/booking_introduction.png"

class Introduction extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["introduction"]}>
          <img className={styles["introduction-img"]} src={introduction}></img>
          <div className={styles["introduction-text"]}>Book Tarot Reader</div>
        </div>
      </div>
    );
  }
}
export default Introduction;
