import React from "react";
import image from "../../../../images/BlogIntroduction1.png";
import styles from "../Blog.module.scss";

class BlogIntroduction extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["introduction"]}>
          <div className={styles["introduction-image"]}>
            <img className={styles["image"]} src={image}></img>
          </div>
          <div className={styles["text-container"]}>
            <div className={styles["title"]}>Soul Reader Blog</div>
            <div className={styles["description"]}>
              Khám phá cách Tarot mở ra cánh cửa tâm linh bên trong bạn
            </div>
          </div>
        </div>
        <div className={styles["blog-list"]}></div>
      </div>
    );
  }
}
export default BlogIntroduction;
