import React from "react";
import styles from "./ReaderProfile.module.scss";
import Profile from "./Profile/Profile.module";
import FeedBack from "./FeedBack/FeedBack.module";

class ReaderProfile extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["reader-profile-container"]}>
          <Profile />
          <FeedBack />
        </div>
      </div>
    );
  }
}

export default ReaderProfile;
