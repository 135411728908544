import React from "react";
import BlogTab from "./BlogListDetail/BlogTab";
import styles from "./Bloglist.module.scss";

class BlogList extends React.Component {
  render() {
    return (
      <div className={styles["tab-container"]}>
        {[...Array(15)].map((_, index) => {
          return <BlogTab id={index}></BlogTab>;
        })}
      </div>
    );
  }
}
export default BlogList;
