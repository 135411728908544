import styles from "./SlotCheck.module.scss";
import classNames from "classnames";

const SlotCheck = ({ isSelected, isCheck }) => {
  return (
    <div
      className={classNames(styles["checkbox"], {
        [styles["selected"]]: isSelected, 
        [styles["isCheck"]]: isCheck, 
      })}
    ></div>
  );
};

export default SlotCheck;
