import React from "react";
import "./Landingpage.scss";
import "../../styles/Global.scss";
import Headers from "../Layout/Header/Header";
import Footers from "../Layout/Footer/Footer";

class LaningPage extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          <Headers></Headers>
          <div className="content">
          </div>
          <Footers></Footers>
        </div>
      </>
    );
  }
}
export default LaningPage;
