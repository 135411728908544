import React, { useEffect, useState } from "react";
import styles from "./ScheduleDetail.module.scss";
import SlotCheck from "../../../../../components/UI/SlotCheck/SlotCheck";
import Payment from "./payment/Payment.module";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const timeSlots = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
];

const ScheduleDetail = ({
  selectedBundle,
  selectedTime,
  selectedBundleIndex,
  selectedTimeIndex,
}) => {
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    getCurrentWeekNumber()
  );
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [isDropdownSelect, setIsDropdownSelect] = useState(false);

  function getCurrentWeekNumber() {
    const currentDate = new Date();
    return Math.ceil(
      ((currentDate - new Date(currentDate.getFullYear(), 0, 1)) / 86400000 +
        1) /
        7
    );
  }

  function getWeekDays(year, weekNumber) {
    const firstDayOfYear = new Date(year, 0, 1);
    const daysOffset =
      (weekNumber - 1) * 7 +
      (firstDayOfYear.getDay() === 0 ? -6 : 1 - firstDayOfYear.getDay());
    const firstDayOfWeek = new Date(year, 0, 1 + daysOffset);

    return daysOfWeek.map((_, index) => {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + index);
      return date.toLocaleDateString("en-GB");
    });
  }

  function generateAvailableWeeks() {
    const weeks = [];
    const currentYear = new Date().getFullYear();
    for (let i = getCurrentWeekNumber(); i <= 52; i++) {
      weeks.push({
        weekNumber: i,
        label: `${getWeekDays(currentYear, i)[0]} - ${getWeekDays(
          currentYear,
          i
        ).at(-1)}`,
      });
    }
    return weeks;
  }

  useEffect(() => {
    setSelectedWeeks(getWeekDays(new Date().getFullYear(), currentWeekNumber));
    setAvailableWeeks(generateAvailableWeeks());
  }, [currentWeekNumber]);

  const handleSlotClick = (dayIndex, slotIndex) => {
    setSelectedSlots((prev) => {
      const exists = prev.some(
        (slot) => slot[0] === dayIndex && slot[1] === slotIndex
      );
      return exists
        ? prev.filter(
            (slot) => !(slot[0] === dayIndex && slot[1] === slotIndex)
          )
        : [...prev, [dayIndex, slotIndex]];
    });
  };

  const handleWeekChange = (weekNumber) => {
    setCurrentWeekNumber(weekNumber);
  };

  const getCurrentWeekString = () => {
    const currentWeekArray = getWeekDays(
      new Date().getFullYear(),
      currentWeekNumber
    );
    return `${currentWeekArray[0]} - ${currentWeekArray.at(-1)}`;
  };

  return (
    <div>
      <div className={styles["schedule-detail-container"]}>
        <div className={styles["week-selector"]}>
          <div
            className={styles["schedule-option"]}
            onClick={() => setIsDropdownSelect(!isDropdownSelect)}
          >
            {getCurrentWeekString()}
          </div>
          {isDropdownSelect && (
            <div className={styles["dropdown"]}>
              {availableWeeks.map((week) => (
                <div
                  key={week.weekNumber}
                  className={styles["option"]}
                  onClick={() => {
                    handleWeekChange(week.weekNumber);
                    setIsDropdownSelect(false);
                  }}
                >
                  {week.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <table className={styles["table-schedule"]}>
          <thead>
            <tr>
              <th className={styles["slot-check-container"]}></th>
              {daysOfWeek.map((day, index) => (
                <th key={index} className={styles["slot-check-container"]}>
                  {day} <br /> {selectedWeeks[index]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((slot, slotIndex) => (
              <tr key={slotIndex}>
                <td className={styles["slot-check-container"]}>{slot}</td>
                {daysOfWeek.map((_, dayIndex) => (
                  <td
                    key={dayIndex}
                    className={styles["slot-check-container"]}
                    onClick={() => handleSlotClick(dayIndex, slotIndex)}
                  >
                    <SlotCheck
                      isSelected={false}
                      isCheck={selectedSlots.some(
                        (slot) => slot[0] === dayIndex && slot[1] === slotIndex
                      )}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className={`${styles["payment"]} ${
            selectedSlots.length !== 0 ? styles["show"] : ""
          }`}
        >
          <Payment
            price={selectedSlots.length * 5000}
            selectedBundle={selectedBundle}
            selectedTime={selectedTime}
            selectedBundleIndex={selectedBundleIndex}
            selectedTimeIndex={selectedTimeIndex}
          ></Payment>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDetail;
