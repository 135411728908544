import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import faceIcon from "../../../images/facebook-icon.png"
import tiktokIcon from "../../../images/tiktok-icon.png"
import igIcon from "../../../images/Ig-icon.png"


class Footers extends React.Component {
  render() {
    return (
      <div className={styles["footer-container"]}>
        <div className={styles["logo"]}>
          <img
            className={styles["logo-image"]}
            src="https://c.animaapp.com/3r123mfJ/img/group-47486@2x.png"
          ></img>
          <div className={styles["hash-tag"]}>© 2024 SoulReader</div>
        </div>
        <div className={styles["general-information"]}>
          <div className={styles["title"]}>Thông tin chung</div>
          <div className={styles["content-group"]}>
            <Link>
              <div className={styles["content"]}>Hướng dẫn sử dụng</div>
            </Link>
            <Link>
              <div className={styles["content"]}>Chính sách bảo mật</div>
            </Link>
            <Link>
              <div className={styles["content"]}>Điều Khoản sử dụng</div>
            </Link>
            <Link>
              <div className={styles["content"]}>Miễn trừ trách nhiệm</div>
            </Link>
          </div>
        </div>
        <div className={styles["connection"]}>
          <div className={styles["connection-container"]}>
            <div className={styles["title"]}>Liên hệ</div>
            <div className={styles["information-container"]}>
              <div className={styles["phone-number"]}>SĐT: 000-000-0000</div>
              <div className={styles["email"]}>Email: abc@gmail.com</div>
              <div className={styles["icon"]}>
                <div className={styles["facebook-icon"]}>
                  <Link>
                    <img className={styles["facebook-icon"]} src={faceIcon}></img>
                  </Link>
                </div>
                <div className={styles["tiktok-icon"]}>
                  <Link>
                    <img className={styles["facebook-icon"]} src={tiktokIcon}></img>
                  </Link>
                </div>
                <div className={styles["instagram-icon"]}>
                  <Link>
                    <img className={styles["facebook-icon"]} src={igIcon}></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footers;
