import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import { apiService } from "../../../env/apiService";

function Headers() {
  const [authorized, setAuthorized] = useState(false);
  const [userName, setUserName] = useState("");
  const [wantLougout, setWantLogout] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refresToken");

      if (token) {
        const body = new FormData();
        body.append("accessToken", token);
        body.append("refreshToken", refreshToken);

        try {
          const apiResponse = await apiService.post("Auth/User", body);
          console.log(apiResponse.data);
          setUserName(apiResponse.userName);
          setAuthorized(true);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setAuthorized(false);
        }
      } else {
        setAuthorized(false);
      }
    };

    fetchUserData();
  }, []);

  // Xử lý đăng xuất
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setAuthorized(false);
    setUserName("");
  };

  return (
    <div className={styles["header-container"]}>
      <Link to={`/`}>
        <div className={styles["logo"]}>
          <img
            className={styles["logo-image"]}
            src="https://c.animaapp.com/3r123mfJ/img/group-47486@2x.png"
            alt="logo"
          />
        </div>
      </Link>

      <div className={styles["navigater"]}>
        <Link className={`${styles["nav-item"]} home`} to="/">
          Trang chủ
        </Link>
        <Link className={`${styles["nav-item"]} home`} to="/Cards">
          Trải bài online
        </Link>
        <Link className={`${styles["nav-item"]} home`} to="/Readers">
          Tarot Reader
        </Link>
        <Link className={`${styles["nav-item"]} home`} to="/Blogs">
          Blog
        </Link>
        <Link className={`${styles["nav-item"]} home`} to="/Appointments">
          Lịch hẹn
        </Link>
      </div>

      <div className={styles["authenticate"]}>
        {authorized ? (
          <div
            className={styles["user-info"]}
            onClick={() => setWantLogout(!wantLougout)}
          >
            <div className={styles["infor container"]}>
              <span className={styles["user-name"]}>{userName}</span>
            </div>
            {wantLougout && (
              <div className={styles["dropdown-menu"]}>
                <button
                  className={styles["dropdown-item"]}
                  onClick={handleLogout}
                >
                  Đăng xuất
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className={styles["auth-links"]}>
            <div className={styles["authen-background"]}>
              <Link className={styles["authen-text"]} to="/Auth/signin">
                Đăng Ký
              </Link>
            </div>
            <div className={styles["authen-background"]}>
              <Link className={styles["authen-text"]} to="/Auth/login">
                Đăng nhập
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Headers;
