import { useNavigate } from "react-router-dom";
import styles from "./Payment.module.scss";

const Payment = ({
  price,
  selectedBundle,
  selectedTime,
  selectedBundleIndex,
  selectedTimeIndex,
}) => {
  const navigate = useNavigate();

  const handleOrder = () => {
    navigate(`Payment/123`, {
      state: {
        totalPrice: price,
        selectedBundle: selectedBundle,
        selectedTime: selectedTime,
        selectedBundleIndex: selectedBundleIndex,
        selectedTimeIndex: selectedTimeIndex,
      },
    });
  };

  return (
    <div className={styles["payment-container"]}>
      <div className={styles["total-payment"]}>TỔNG THANH TOÁN</div>
      <div className={styles["price"]}>đ {price}</div>
      <div className={styles["button-paynow"]} onClick={handleOrder}>
        <div className={styles["paynow-text"]}>Đặt Ngay</div>
      </div>
    </div>
  );
};

export default Payment;
