import React, { useState } from "react";
import "../../Auth/style/Auth.scss";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = ({ loginHandler }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const data = await loginHandler.HandleLogin(username, password);
      console.log("Login success:", data);
      setErrorMessage("");
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login">
      <div className="div">
        <div className="card">
          <div className="overlap-group">
            <div className="ellipse" />
            <img className="image" alt="Image" src="https://c.animaapp.com/v0sAEoVB/img/image@2x.png" />
            <img className="img" alt="Image" src="https://c.animaapp.com/v0sAEoVB/img/image-1@2x.png" />
            <img className="image-2" alt="Image" src="https://c.animaapp.com/v0sAEoVB/img/image-2@2x.png" />
          </div>
        </div>

        <div className="login-input">
          <div className="text-wrapper-9">ĐĂNG NHẬP</div>
          <div className="container">
            <div className="input-container">
              <div className="input">
                <div className="user-name">
                  <div className="overlap-group-4">
                    <input
                      id="username"
                      type="text"
                      value={username}
                      placeholder="Tên đăng nhập"
                      className="input-field"
                      onChange={(e) => handleChange(e, setUsername)}
                    />
                    <img className="frame" alt="Frame" src="https://c.animaapp.com/v0sAEoVB/img/frame-1@2x.png" />
                  </div>
                </div>

                <div className="password">
                  <div className="overlap-group-4">
                    <input
                      id="password"
                      type="password"
                      placeholder="Mật khẩu"
                      className="input-field"
                      value={password}
                      onChange={(e) => handleChange(e, setPassword)}
                    />
                    <img className="frame" alt="Frame" src="https://c.animaapp.com/v0sAEoVB/img/frame@2x.png" />
                  </div>
                </div>
              </div>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <div className="group-7">
                <div className="remember-me">
                  <label className="custom-checkbox">
                    <input type="checkbox" className="checkbox-input" />
                    <div className="rectangle"></div>
                  </label>
                  <div className="text-wrapper-6">Ghi nhớ mật khẩu</div>
                </div>
                <Link className="text-wrapper-5" to="/Auth/Reset-password">Quên mật khẩu ?</Link>
              </div>

              <div className="group-6">
                <button className="overlap-group-3" onClick={handleLogin}>
                  <div className="text-wrapper-4">Đăng nhập ngay</div>
                </button>
              </div>

              <p className="b-n-kh-ng-c-t-i-kho">
                Bạn không có tài khoản? <Link className="span" to="/Auth/signin">Đăng ký tại đây!</Link>
              </p>
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img className="subtract" alt="Subtract" src="https://c.animaapp.com/v0sAEoVB/img/subtract@2x.png" />
                <p className="ng-nh-p-kh-c">Đăng nhập khác</p>
              </div>
            </div>
            <div className="group-2">
              <a href="#" className="group-wrapper">
                <div className="group-4">
                  Đăng nhập với
                  <img className="img-2" alt="Google" src="https://c.animaapp.com/v0sAEoVB/img/google-1@2x.png" />
                </div>
              </a>
              <a href="#" className="div-wrapper">
                <div className="group-4">
                  Đăng nhập với
                  <img className="img-2" alt="Facebook" src="https://c.animaapp.com/v0sAEoVB/img/facebook-1@2x.png" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <Link to="/">
          <img className="logo" alt="Logo" src="https://c.animaapp.com/v0sAEoVB/img/logo@2x.png" />
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
