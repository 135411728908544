import React, { Component } from "react";
import Headers from "../Layout/Header/Header";
import "../../styles/Global.scss";
import Footers from "../Layout/Footer/Footer";
import styles from "./ReaderStyle.module.scss";
import ReaderTab from "./ReaderDetail/ReaderInformation";
import { Range } from "react-range";

class Reader extends Component {
  state = {
    values: [0, 500000],
    starVote: 0,
    starHover: 0,
    sortSelected: null,
    openSort: false,
    currentPage: 1,
  };

  MAX_ITEMS_PER_PAGE = 10;
  tarorReaders = Array.from({ length: 12 }, (_, index) => index);
  sortByList = [
    "Giá giảm dần",
    "Giá tăng dần",
    "Giá sao giảm dần",
    "Giá sao tăng dần",
  ];

  handleRangeChange = (values) => this.setState({ values });

  handleStarHover = (starValue) => this.setState({ starHover: starValue });

  handleStarLeave = () => this.setState({ starHover: 0 });

  handleStarClick = (starValue) => this.setState({ starVote: starValue });

  toggleDropdown = () =>
    this.setState(() => ({ openSort: !this.state.openSort }));

  handleChangeSort = (sort) =>
    this.setState({ sortSelected: sort, openSort: false });

  handlePageChange = (direction) => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + direction,
    }));
  };

  render() {
    const { values, starVote, starHover, sortSelected, openSort, currentPage } =
      this.state;
    const totalPages = Math.ceil(
      this.tarorReaders.length / this.MAX_ITEMS_PER_PAGE
    );
    const displayedReaders = this.tarorReaders.slice(
      (currentPage - 1) * this.MAX_ITEMS_PER_PAGE,
      currentPage * this.MAX_ITEMS_PER_PAGE
    );

    return (
      <div className="home">
        <div className={styles.div}>
          <Headers />
          <div className={styles.introduction}>
            <div className={styles["image-container"]}>
              <img
                className={styles["tarot-image"]}
                alt=""
                src="https://c.animaapp.com/0heN5NVk/img/image-12.png"
              />
              <div className={styles["overlay-text"]}>Tarot Reader</div>
            </div>
          </div>

          {/* Sort Dropdown */}
          <div className={styles["sort-by"]}>
            <div className={styles["sort-text"]}>Sắp xếp theo</div>
            <div
              className={styles["sort-option"]}
              onClick={this.toggleDropdown}
            >
              <div className={styles["sort-value"]}>
                {sortSelected !== null
                  ? this.sortByList[sortSelected]
                  : "Tiêu chí sắp xếp"}
              </div>
            </div>

            <div
              className={`${styles["dropdown-list-sort"]} ${
                openSort ? styles["open"] : ""
              }`}
            >
              {this.sortByList.map((sort, index) => (
                <div
                  key={index}
                  className={styles["sort-bar-value"]}
                  onClick={() => this.handleChangeSort(index)}
                >
                  {sort}
                </div>
              ))}
            </div>
          </div>

          {/* Reader List */}
          <div className={styles["body"]}>
            <div className={styles["reader-list"]}>
              {displayedReaders.map((_, index) => (
                <div className={styles.reader} key={index}>
                  <ReaderTab id={index} />
                </div>
              ))}

              {totalPages > 1 && (
                <div className={styles.pagination}>
                  <button
                    disabled={currentPage === 1}
                    onClick={() => this.handlePageChange(-1)}
                  >
                    ← Trước
                  </button>
                  <span>
                    Trang {currentPage} / {totalPages}
                  </span>
                  <button
                    disabled={currentPage === totalPages}
                    onClick={() => this.handlePageChange(1)}
                  >
                    Tiếp →
                  </button>
                </div>
              )}
            </div>

            <div className={styles.filter}>
              <div className={styles.price}>
                <div className={styles["price-tile"]}>Khoảng giá</div>
              </div>
              <div className={styles["price-range"]}>
                <div className={styles["price-box"]}>
                  <div className={styles["price-value"]}>{values[0]}đ</div>
                </div>
                <div className={styles.vecter}>
                  <img
                    alt="Vector"
                    src="https://c.animaapp.com/O8yYDpAn/img/vector-21@2x.png"
                  />
                </div>
                <div className={styles["price-box"]}>
                  <div className={styles["price-value"]}>{values[1]}đ</div>
                </div>
              </div>
              <div className={styles["slide-container"]}>
                <Range
                  step={1000}
                  min={0}
                  max={500000}
                  values={values}
                  onChange={this.handleRangeChange}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "10px",
                        width: "216px",
                        backgroundColor: "#C605C3",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "16px",
                        width: "16px",
                        backgroundColor: "#FFF",
                        borderRadius: "50%",
                        cursor: "pointer",
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                      }}
                    ></div>
                  )}
                />
              </div>

              {/* Star Filter */}
              <div className={styles["filter-star"]}>
                <div className={styles["evaluate-tile"]}>Đánh giá</div>
                <div className={styles["start-value"]}>
                  {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    return (
                      <img
                        key={index}
                        className={styles["star-vote"]}
                        src={
                          starValue <= starVote || starValue <= starHover
                            ? "https://c.animaapp.com/O8yYDpAn/img/image-11-2@2x.png"
                            : "https://c.animaapp.com/O8yYDpAn/img/star-5@2x.png"
                        }
                        onMouseEnter={() => this.handleStarHover(starValue)}
                        onMouseLeave={this.handleStarLeave}
                        onClick={() => this.handleStarClick(starValue)}
                        style={{ cursor: "pointer", transition: "0.2s" }}
                        alt={`star-${starValue}`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footers />
        </div>
      </div>
    );
  }
}

export default Reader;
