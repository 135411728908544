import logo from "./logo.svg";
import "./App.css";
import LaningPage from "./views/Landingpage/LaningPage";
import bootstrap from "bootstrap";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";



function App() {
  return (
    <BrowserRouter>
        <div className="App">
          <header className="App-header">
            <AppRoutes/>
          </header>
        </div>  
   </BrowserRouter>
  );
}

export default App;
