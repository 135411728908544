import React from "react";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import Introduction from "./Introduction/Introduction.module";
import ReaderProfile from "./ReaderProfile/ReaderProfile.module";
import Schedule from "./Schedule/Schedule.module";

class OrderReader extends React.Component {
  render() {
    return (
      <div className="home">
        <Headers></Headers>
        <div className="content">
            <Introduction></Introduction>
            <ReaderProfile></ReaderProfile>
            <Schedule></Schedule>
        </div>
        <Footers></Footers>
      </div>
    );
  }
}
export default OrderReader;
