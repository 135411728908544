import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import styles from "./calling.module.scss";
import {
  Mic,
  MicOff,
  Video,
  VideoOff,
  PhoneOff,
  ScreenShare,
} from "lucide-react";

function Calling() {
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);

  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const webcamRef = useRef(null);
  const screenVideoRef = useRef(null);
  let screenStream = useRef(null);

  const stopScreenSharing = () => {
    console.log("is stop");
    if (screenStream.current) {
      screenStream.current.getTracks().forEach((track) => track.stop());
      screenStream.current = null;
    }
    setIsScreenSharing(false);
  };

  const handleShareMonitor = async () => {
    if (!isScreenSharing) {
      console.log("is open");

      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        screenStream.current = stream;
        setIsScreenSharing(true);
        if (screenVideoRef.current) {
          screenVideoRef.current.srcObject = stream;
        }
        stream.getVideoTracks()[0].onended = () => {
          stopScreenSharing();
        };
      } catch (error) {
        console.error(" error sharing monitor", error);
      }
    } else {
      stopScreenSharing();
    }
  };
  useEffect(() => {
    if (isScreenSharing && screenStream.current && screenVideoRef.current) {
      screenVideoRef.current.srcObject = screenStream.current;
    }
  }, [isScreenSharing]);
  return (
    <div className={styles["container"]}>
      <div className={styles["video-container"]}>
        {isScreenSharing && (
          <div className={styles["sharing"]}>
            <video
              ref={screenVideoRef}
              autoPlay
              className={styles["screen-share"]}
            />
            <div className={styles["camera-user-container"]}>
              {isCameraOn ? (
                <Webcam ref={webcamRef} className={styles["video-screan"]} />
              ) : (
                <div className={styles["video-screan"]}>Camera Tắt</div>
              )}

              <div className={styles["video-screan"]}>Người nhận</div>
            </div>
          </div>
        )}

        {!isScreenSharing && (
          <div className={styles["no-sharing"]}>
            {isCameraOn ? (
              <Webcam ref={webcamRef} className={styles["video-screan"]} />
            ) : (
              <div className={styles["video-screan"]}>Camera Tắt</div>
            )}

            <div className={styles["video-screan"]}>Người nhận</div>
          </div>
        )}
      </div>
      <div className={styles["control"]}>
        <button
          onClick={() => setIsMicOn(!isMicOn)}
          className={`${styles["control-button"]} ${
            !isMicOn ? styles["turn-off"] : ""
          }`}
        >
          {isMicOn ? <Mic /> : <MicOff />}
        </button>
        <button
          onClick={() => setIsCameraOn(!isCameraOn)}
          className={`${styles["control-button"]} ${
            !isCameraOn ? styles["turn-off"] : ""
          }`}
        >
          {isCameraOn ? <Video /> : <VideoOff />}
        </button>
        <button
          onClick={handleShareMonitor}
          className={`${styles["control-button"]} ${
            isScreenSharing ? styles["turn-off"] : ""
          }`}
        >
          <ScreenShare />
        </button>
        <button className={styles["end-call"]}>
          <PhoneOff />
        </button>
      </div>
    </div>
  );
}

export default Calling;
