import React from "react";
import { Route, Routes } from "react-router-dom";
import Reader from "../../views/Reader/Reader";
import ReaderDetail from "../../views/Reader/ReaderDetail/ReaderDetail";
import OrderReader from "../../views/Reader/Order/OrderReader";
import OrderPayment from "../../views/Reader/Order/OrderDetail/OrderDetail.module";

class ReaderRoutes extends React.Component {
  
  render() {
    return (
      <Routes>
        <Route path="/" element={<Reader></Reader>}></Route>
        <Route path="/:id" element={<ReaderDetail> </ReaderDetail>}/>
        <Route path="/Orders/:id" element={<OrderReader></OrderReader>}/>
        <Route path="/Orders/:id/Payment/:id" element={<OrderPayment></OrderPayment>}></Route>
      </Routes>
    );
  }
}
export default ReaderRoutes;
