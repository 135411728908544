import Calling from "./calling/calling.module";
import styles from "./content.module.scss";

function Content() {
  return (
    <div className={styles["container"]}>
      <div className={styles["introduction"]}>
        Chào mừng bạn đến với buổi trải nghiệm Tarot! Rất vui khi được đồng hành
        cùng bạn trong hành trình khám phá bản thân và giải mã những thông điệp
        từ vũ trụ. Hãy thư giãn và để các lá bài dẫn lối, giúp bạn tìm thấy câu
        trả lời mà bạn đang tìm kiếm. Chúc bạn có một buổi trải nghiệm thật thú
        vị và ý nghĩa!
      </div>
      <div className={styles["calling"]}>
        <Calling></Calling>
      </div>
    </div>
  );
}
export default Content;
