import styles from "./Introduction.module.scss";
import introduction from "../../../images/booking_introduction.png";

function Introduction() {
  return (
    <div className={styles["introduction"]}>
      <img className={styles["introduction-img"]} src={introduction}></img>
      <div className={styles["introduction-text"]}>Lịch hẹn</div>
    </div>
  );
}
export default Introduction;
