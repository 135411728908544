import React, { useState } from "react";
import styles from "./Schedule.module.scss";
import ScheduleDetail from "./ScheduleDetail/ScheduleDetail.module";

const bundleOptions = ["Câu hỏi lẻ", "Gói thời gian", "Gói chủ đề"];
const timeOptions = ["30 phút", "60 phút", "120 phút"];

const Schedule = () => {
  const [showBundleOptions, setShowBundleOptions] = useState(false);
  const [showTimeOptions, setShowTimeOptions] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);

  const [selectedBundle, setSelectedBundle] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedBundleIndex, setSelectedBundleIndex] = useState(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);

  const handleBundleSelect = (option, index) => {
    setSelectedBundle(option);
    setSelectedBundleIndex(index);
    setShowBundleOptions(false);
  };

  const handleTimeSelect = (option, index) => {
    setSelectedTime(option);
    setSelectedTimeIndex(index);
    setShowTimeOptions(false);
  };

  const handleShowSchedule = () => {
    if (selectedBundle !== null && selectedTime !== null) {
      setShowSchedule(!showSchedule);
    } else {
    }
  };

  return (
    <div className={styles["schedule-container"]}>
      <div className={styles["selected-bundle"]}>
        <div
          className={styles["bundle-option"]}
          onClick={() => setShowBundleOptions(!showBundleOptions)}
        >
          {selectedBundle !== null ? selectedBundle : "CHỌN GÓI"}
          {showBundleOptions && (
            <div className={styles["dropdown"]}>
              {bundleOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles["option"]}
                  onClick={() => handleBundleSelect(option, index)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>

        <div
          className={styles["time-option"]}
          onClick={() => setShowTimeOptions(!showTimeOptions)}
        >
          {selectedTime !== null ? selectedTime : "CHỌN THỜI GIAN"}
          {showTimeOptions && (
            <div className={styles["dropdown"]}>
              {timeOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles["option"]}
                  onClick={() => handleTimeSelect(option, index)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles["booking-schedule"]}>
        <div className={styles["schedule-option-container"]}>
          <div
            className={styles["schedule-option"]}
            onClick={() => handleShowSchedule()}
          >
            Đặt lịch
          </div>
        </div>
        <div
          className={`${styles["schedule"]} ${
            showSchedule ? styles["show"] : ""
          }`}
        >
          <ScheduleDetail
            selectedBundle={selectedBundle}
            selectedTime={selectedTime}
            selectedBundleIndex = {selectedBundleIndex}
            selectedTimeIndex = {selectedTimeIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
