import Introduction from "../appointment/introduction/Introduction.module";
import Footers from "../Layout/Footer/Footer";
import Headers from "../Layout/Header/Header";
import Content from "./content/content.module";

function Meeting() {
  return (
    <div className="home">
      <Headers></Headers>
      <div className="content">
        <Introduction></Introduction>
        <Content></Content>
      </div>

      <Footers></Footers>
    </div>
  );
}
export default Meeting;
