import Footers from "../../Layout/Footer/Footer";
import Headers from "../../Layout/Header/Header";
import styles from "./Apointment.module.scss";
import Detail from "./ListDetail/detail.module";

function AppointList() {
  // Danh sách 5 lịch hẹn
  const appointments = [
    { id: 1, name: "Nguyễn Văn A", package: "Coi theo giờ (1 tiếng)", time: "26/10/24 - 12PM" },
    { id: 2, name: "Trần Thị B", package: "Gói tư vấn 30 phút", time: "27/10/24 - 2PM" },
    { id: 3, name: "Phạm Văn C", package: "Gói nâng cao 2 tiếng", time: "28/10/24 - 4PM" },
    { id: 4, name: "Lê Thị D", package: "Gói đặc biệt", time: "29/10/24 - 6PM" },
    { id: 5, name: "Hoàng Văn E", package: "Coi theo giờ (3 tiếng)", time: "30/10/24 - 8PM" }
  ];

  return (
    <div className="home">
      <Headers />
      <div className="content">
        <div className={styles["title"]}>Lịch Hẹn</div>
        <div className={styles["content"]}>
          <div className={styles["detail-container"]}>
            {appointments.map((appointment) => (
              <Detail key={appointment.id} data={appointment} />
            ))}
          </div>
        </div>
      </div>
      <Footers />
    </div>
  );
}

export default AppointList;
