import Footers from "../Layout/Footer/Footer";
import Headers from "../Layout/Header/Header";
import Content from "./content/content.module";
import Introduction from "./introduction/Introduction.module";

function Appointment() {
 
    return(
        <div className="home">
            <Headers></Headers>
            <div className="content">
                <Introduction></Introduction>
                <Content></Content>
            </div>
            <Footers></Footers>
        </div>
    )
}
export default Appointment;