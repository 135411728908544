import { useLocation } from "react-router-dom";
import Introduction from "../Introduction/Introduction.module";
import styles from "./OrderDetail.module.scss";
import Headers from "../../../Layout/Header/Header";
import Footers from "../../../Layout/Footer/Footer";
import Profile from "../ReaderProfile/Profile/Profile.module";
import zaloPay from "../../../../images/zalo-pay.png";
import momoPay from "../../../../images/momp.png";
import { useState } from "react";

const OrderPayment = () => {
  const location = useLocation();
  const totalPrice = location.state?.totalPrice || 0;
  const selectedBundle= location.state?.selectedBundle || ""
  const selectedTime=  location.state?.selectedTime || ""
  const selectedBundleIndex=  location.state?.selectedBundleIndex 
  const selectedTimeIndex=  location.state?.selectedTimeIndex 

  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);

  return (
    <div className="home">
      <Headers></Headers>
      <Introduction></Introduction>
      <div className={styles["order-container"]}>
        <div className={styles["title"]}>Thông tin reader</div>
        <div className={styles["profile"]}>
          <Profile></Profile>
        </div>
        <div className={styles["sumarize-order"]}>
          <div>Tóm tắt đơn hàng</div>
          <div className={styles["order-detail-container"]}>
            <div className={styles["information"]}>
              <div className={styles["bundle"]}>
                <div className={styles["bundle-tile"]}>Chọn gói</div>
                <div className={styles["content"]}>{selectedBundle} - {selectedTime}</div>
              </div>

              <div className={styles["bundle"]}>
                <div className={styles["bundle-tile"]}>Thời gian</div>
                <div className={styles["content"]}>11AM - 26/10/24</div>
              </div>
              <div className={styles["bundle"]}>
                <div className={styles["bundle-tile"]}>Giá</div>
                <div className={styles["content"]}>{totalPrice}VND</div>
              </div>
            </div>
            <div className={styles["payment"]}>
              <div className={styles["payment-title"]}>
                Phương thức thanh toán
              </div>
              <div className={styles["payment-method"]}>
                <img
                  src={zaloPay}
                  className={`${styles["zaloPay"]} ${
                    paymentMethodSelected === 1 ? styles["show"] : ""
                  }`}
                  onClick={() => setPaymentMethodSelected(1)}
                ></img>
                <img
                  src={momoPay}
                  className={`${styles["momoPay"]} 
                    ${paymentMethodSelected === 2 ? styles["show"] : ""}`}
                  onClick={() => setPaymentMethodSelected(2)}
                ></img>
              </div>
            </div>
            <div className={styles["button-paynow"]}>
              <div className={styles["paynow-text"]}>Đặt Ngay</div>
            </div>
          </div>
        </div>
      </div>
      <Footers></Footers>
    </div>
  );
};
export default OrderPayment;
