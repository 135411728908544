import React from "react";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import styles from "./Blog.module.scss";
import BlogIntroduction from "./Component/BlogIntroduction";
import BlogList from "./Component/BlogListTab/BlogList";

class Blog extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          {/* <div className="div"> */}
            <Headers></Headers>
            <div className={styles["blog-container"]}>
                <BlogIntroduction></BlogIntroduction>
                <BlogList></BlogList>
            </div>
            <Footers></Footers>
          {/* </div> */}
        </div>
      </>
    );
  }
}
export default Blog;
