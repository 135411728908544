import React from "react";
import styles from "./ReaderContainer.module.scss";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap
import { Link } from "react-router-dom";

class ReaderTab extends React.Component {
  render() {
    const readerID = this.props.id;
    return (
      <div className="">
        <div className={`${styles["reader-container"]} p-4 shadow rounded`}>
          <div className={styles["image-reader"]}>
            <Link to={`/Readers/${readerID}`}>
              <img
                className={styles["avatar-image"]}
                src="https://c.animaapp.com/0heN5NVk/img/image-6@2x.png"
                alt="avatar image"
              />
            </Link>
          </div>
          <div className={styles["information-reader"]}>
            <div className={styles["role-experience"]}>
              <div className={styles["role"]}>Chuyên gia Tarot</div>
              <div className={styles["experience"]}>5+ năm kinh nghiệm</div>
            </div>
            <div className={styles["name-reader"]}>Tuyen LTT</div>
            <div className={styles["feedback"]}>
              <div className={styles["star"]}>
                {[...Array(5)].map((_, index) => (
                  <img
                    key={index}
                    className={styles["start-image"]}
                    alt="Image"
                    src="https://c.animaapp.com/O8yYDpAn/img/image-11-2@2x.png"
                  />
                ))}
              </div>
              <div className={styles["avarage-star"]}>4.6</div>
              <div className={styles["num-customer"]}>130 khách hàng</div>
            </div>

            <div className={styles["booking-order"]}>
              <div className={styles["price"]}>
                <div className={styles["text-wrapper-36"]}>đ</div>

                <div className={styles["text-wrapper-37"]}>10k - 300k</div>
              </div>
              <div className={styles["bi-button"]}>
                <div className={styles["overlap-group-5"]}>
                  <div className={styles["text-wrapper-34"]}>Bói ngay</div>
                </div>
              </div>
            </div>
            <div className={styles["detail-content"]}>
              Dày dặn kinh nghiệm trong việc sử dụng Tarot để định hướng và khai
              mở tiềm năng cho người xem, với kỹ năng lắng nghe sâu sắc và trực
              giác nhạy bén. Chuyên nghiệp trong việc tạo không gian an toàn,
              giúp khách hàng tìm thấy sự ... Xem thêm
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReaderTab;
