import React from "react";
import { apiService } from "../../env/apiService";

class LoginHandler extends React.Component {
  HandleLogin = async (email, password) => {
    if (!email || !password) {
      throw new Error("Vui lòng điền đầy đủ thông tin.");
    }
    try {
      const body = { email, password };
      const apiResponse = await apiService.post("/Auth/Login", body);
      console.log(apiResponse);
      localStorage.setItem("token",apiResponse.accessToken);
      localStorage.setItem("refresToken", apiResponse.refreshToken);
      return apiResponse.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Đăng nhập thất bại.");
    }
  };

  render() {
    return React.cloneElement(this.props.children, { LoginHandler: this });
  }
}

export default LoginHandler;

