import React from "react";

import styles from "./Feedback.module.scss";

class Comment extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <div className={styles["comment-container"]}>
          <div className={styles["comment-input"]}>
            <div className={styles["input-container"]}>
              <div className={styles["avatar-input"]}></div>
              <div className={styles["input-text"]}></div>
            </div>
            <div className={styles["send-btn"]}>
              <div className={styles["send-text"]}>Gửi</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Comment;
