import React from "react";
import styles from "./FeedBack.module.scss"

class FeedBack extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["introduction"]}>
        </div>
      </div>
    );
  }
}
export default FeedBack;
