import React from "react";
import styles from "./NewestBlog.module.scss";
import NewestBlogItem from "./NewestBlogItem/NewestBlogItem";
class NewestBlog extends React.Component {
  render() {
    return (
      <div className={styles["newest-containter"]}>
        <div className={styles["title"]}>Blog mới nhất</div>
        <div className={styles["item-container"]}>
          {[...Array(3)].map(() => {
            return <NewestBlogItem></NewestBlogItem>;
          })}
        </div>
      </div>
    );
  }
}

export default NewestBlog;
