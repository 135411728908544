import React from "react";
import styles from "./BlogDetail.module.scss";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import Introduction from "./Introduction/Introduction";
import BlogContentDetail from "./Conten/BlogDetailContent";
import NewestBlog from "./NewestBlog/NewestBlog";
class BlogDetail extends React.Component {
  render() {
    return (
      <div className="home">
        <Headers></Headers>
        <div className={styles["blog-detail-container"]}>
          <div className={styles["introduction"]}>
            <Introduction></Introduction>
          </div>
          <div className={styles["content-container"]}>
            <BlogContentDetail></BlogContentDetail>
            <NewestBlog></NewestBlog>
          </div>
        </div>
        <Footers></Footers>
      </div>
    );
  }
}
export default BlogDetail;
