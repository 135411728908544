import React from "react";
import styles from "./BlogDetailContent.module.scss";
import imgage from "../../../../images/Blog main image.png";
class BlogContentDetail extends React.Component {
  render() {
    return (
      <div>
        <div className={styles["introduction"]}>
          <div className={styles["title"]}>Tổng quan về bộ bài Tarot</div>
          <div className={styles["date"]}>
            <div className={styles["date-time"]}> 24/10/2024 | 9 phút</div>
            <div className={styles["role"]}>
              <div className={styles["role-name"]}>Tarot</div>
            </div>
          </div>
          <img className={styles["blog-detail-image"]} src={imgage}></img>
        </div>
        <div className={styles["content-container"]}>
          <div className={styles["content"]}>Bài Tarot là gì?</div>
          <div className={styles[""]}>
            Tarot là tên gọi của một cỗ bài 78 lá, có nguồn gốc từ Châu Âu thế
            kỷ 15. Từ chỗ chỉ là một bộ bài in hình các biểu tượng được sử dụng
            cho mục đích giải trí, bài Tarot dần được nghiên cứu, chứng minh,
            phát triển và ứng dụng vào các lĩnh vực như phát triển bản thân,
            giải quyết vấn đề, tư vấn cho lời khuyên và khám phá bản chất sự
            việc. Bài Tarot có mối liên hệ với các bộ môn khoa học về Chiêm
            Tinh, Hoàng Đạo, và Tiên Tri. Hãy tham khảo Bài Tarot Là Gì và bạn
            sẽ nắm được những thông tin cơ bản nhất về bài Tarot.
          </div>
        </div>
      </div>
    );
  }
}

export default BlogContentDetail;
